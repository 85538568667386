import { Injectable } from '@angular/core';
import { ToasterService } from 'angular2-toaster';

import { LanguageService } from './language.service';

interface ErrorMessage {
  translate?: boolean;
  key: string;
}

type ErrorMessageType = (ErrorMessage | string)[];

interface ValidationErrorMessage {
  [key: string]: ErrorMessageType;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private toastService: ToasterService,
    private languageService: LanguageService
  ) { }

  private toast(
    type: 'error' | 'warning',
    message: ErrorMessageType | ValidationErrorMessage,
    locations: string[]
  ): void {
    let msg: ErrorMessageType = [];
    if (message.toString() === '[object Object]') {
      msg = Object.values(<ValidationErrorMessage>message).reduce((acc, val) => acc.concat(val), []);
    } else { msg = <ErrorMessageType>message; }
    const messages = msg.map(m => {
      if (typeof m === 'string') { return <string>m;
      } else if ((<ErrorMessage>m).key) {
        return this.getTranslatedString((<ErrorMessage>m).key);
      }
    });
    const location = locations.join('.');
    this.toastService.pop(type, ...messages);
  }

  public error(msg: (ErrorMessage | string)[], location: string[]) {
    this.toast('error', msg, location);
  }

  public warn(msg: (ErrorMessage | string)[], location: string[]) {
    this.toast('warning', msg, location);
  }

  private getTranslatedString(str: string) {
    let translated_string = '';
    this.languageService.getTranslationUsingKeyString(str)
      .subscribe(
        tranlatedData => {
          if (typeof tranlatedData === 'string') {
            translated_string = tranlatedData;
          }
        }
      );
    return translated_string;
  }
}
