import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class StatsService {

  constructor(private http: HttpClient) {
  }

  getCampaignWithMessage() {
    return this.http.get<any>(`${environment.API}/influencer/stats`);
  }

  getCampaignInterestIcon() {
    return this.http.get<any>(`${environment.API}/influencer/stats/icons`);
  }

}
