import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { InfluencerPayment } from '../models/influencer-payment.model';

@Injectable()
export class PaymentService {

  private static outstandingPayment = new Subject<any>();
  private static completedPayment = new Subject<any>();

  constructor(private http: HttpClient) {
  }

  refreshOutStandingPayments() {
    this.http.get<any>(`${environment.API}/influencer/payment/outstanding`)
      .subscribe((payments: any[]) => {
          PaymentService.outstandingPayment.next(
            payments.map((payment: any) => new InfluencerPayment().deserialize(payment))
          );
        },
        error => {
          PaymentService.outstandingPayment.next({error: true});
        });
  }

  getOutstandingPayments(): Observable<InfluencerPayment[]> {
    return PaymentService.outstandingPayment.asObservable();
  }

  refreshCompletedPayments() {
    this.http.get<any>(`${environment.API}/influencer/payment/completed`)
      .subscribe((payments: any[]) => {
          PaymentService.completedPayment.next(
            payments.map((payment: any) => new InfluencerPayment().deserialize(payment))
          );
        },
        error => {
          PaymentService.completedPayment.next({error: true});
        });
  }

  getCompletedPayments() {
    return PaymentService.completedPayment.asObservable();
  }

  uploadInvoice(media) {
    const header = new HttpHeaders();
    header.append('Accept', 'multipart/form-data');
    return this.http.post<any>(`${environment.API}/influencer/payment/invoice`, media, {headers: header});
  }
}
