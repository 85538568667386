import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ValidationMessagesComponent} from './components/validation-message';

import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDatepickerModule, ModalModule, TooltipModule} from 'ngx-bootstrap';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {VgCoreModule} from 'videogular2/compiled/core';
import {VgControlsModule} from 'videogular2/compiled/controls';
import {VgOverlayPlayModule} from 'videogular2/compiled/overlay-play';
import {VgBufferingModule} from 'videogular2/compiled/buffering';
import {VideoPopupComponent} from './components/video-popup';
import {NgSelectModule} from '@ng-select/ng-select';
// Translation package installtion
import {TranslateModule} from '@ngx-translate/core';
// gallery module
import {NgxGalleryModule} from 'ngx-gallery';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {YimifyImagePipe} from './pipe/yimify-image.pipe';
import {WordCloudComponent} from './components/word-cloud/word-cloud.component';
import {TooltipComponent} from './components/tooltip/tooltip.component';
import { FileTypePipe } from './pipe/file-type.pipe';

/*
  Shared Module is used to combine all common module in one module and
  used it anywhere in application whenever required
*/
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxGalleryModule,
    TabsModule,
    TooltipModule.forRoot(),
    NgSelectModule,
    PerfectScrollbarModule,
    TooltipModule.forRoot()
  ],
  declarations: [
    ValidationMessagesComponent,
    VideoPopupComponent,
    WordCloudComponent,
    YimifyImagePipe,
    TooltipComponent,
    FileTypePipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ValidationMessagesComponent,
    ReactiveFormsModule,
    AlertModule,
    TranslateModule,
    BsDatepickerModule,
    ModalModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VideoPopupComponent,
    TabsModule,
    TooltipModule,
    NgSelectModule,
    PerfectScrollbarModule,
    WordCloudComponent,
    YimifyImagePipe,
    FileTypePipe,
    TooltipComponent
  ],
  providers: [
    DatePipe
  ]
})
export class SharedCustomModule {

}
