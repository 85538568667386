import {AbstractControl} from '@angular/forms';

export class DateOfBirthValidator {
  static CheckDateOfBirth(AC: AbstractControl) {
    const date_of_birth = AC.get('date_of_birth').value; // to get value in input tag
    const dob_year = new Date(date_of_birth).getFullYear();
    const current_year = new Date().getFullYear();
    const diff = current_year - dob_year;
    if (diff < 16) {
      AC.get('date_of_birth').setErrors({CheckDateOfBirth: true});
    } else {
      return null;
    }
  }
}
