/*
 * @usage Form Validation service to show message on form
 */
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any, name?: string) {

    const config = {
      'required': `${name} can not be blank.`,
      'email': 'Enter valid email address',
      'minlength': `Minimum length ${validatorValue.requiredLength}`,
      'pattern': 'Must have small/capital character, number and special character'
    };
    return config[validatorName];
  }


  static emailValidator(control) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
}
