import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {CookiesService, UserService} from '../../../../services';
import {Question} from '../../../../models';
import {CarouselConfig} from 'ngx-bootstrap';
import {ErrorService} from '../../../../services/error.service';
import {Observable, Subject} from 'rxjs';
import {Location} from '@angular/common';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-funnel-questions',
  templateUrl: './funnel-questions.component.html',
  styleUrls: ['./funnel-questions.component.scss', '../login/general.login.component.scss'],
  providers: [
    {provide: CarouselConfig, useValue: {interval: 0, noPause: true, showIndicators: false, noWrap: true}}
  ]
})
export class FunnelQuestionsComponent implements OnInit {
  api_error = {};
  success_msg = false;
  funnelQuestions: Question[];
  funnelForm: FormGroup;
  currentSlide: number = 0;
  selectedGender = new Subject();
  gender = '';
  interestArray: any[] = [];
  isValidNumberOfInterestsSelected: boolean = false;
  base_url;

  constructor(private _userService: UserService,
              public fb: FormBuilder,
              private _cookiesService: CookiesService,
              private _router: Router,
              public _route: ActivatedRoute,
              private errorService: ErrorService,
  ) {
    this.initializeForm();
  }


  ngOnInit() {
    this.base_url = location.origin;
    this._userService.getFunnelQuestions()
      .subscribe(
        (response: Question[]) => {
          this.funnelQuestions = response;
          for (const question of this.funnelQuestions) {
            if (question['answer'].length === undefined) {
              const tempArray = [];
              Object.keys(question['answer']).forEach((val, key) => {
                tempArray.push({name: question['answer'][val], value: val});
              });
              question['answer'] = tempArray;
            }
          }
          this.setFields(this.funnelQuestions);
        },
        error => {
          this.errorService.error([{key: 'REGISTER.ERROR_IN_FUNNEL_QUESTION'}], ['user', 'funnel-questions']);
        }
      );
    this.getSelectedGender().subscribe((value) => {
      if (value) {
        this.gender = value;
      } else {
        this.gender = '';
      }
    });
  }

  initializeForm() {
    this.funnelForm = this.fb.group({});
  }

  getType(value: any) {
    return typeof value;
  }

  getKeys(value: any) {
    return Object.keys(value);
  }

  setFields(feildsData: any) {
    for (const feild of feildsData) {
      if (feild.field === 'interests') {
        const controls = feild['answer'].map(c => new FormControl(false, Validators.required));
        this.interestArray = feild['answer'];
        this.funnelForm.addControl(feild.field, this.fb.array(controls));
      } else {
        this.funnelForm.addControl(feild.field, new FormControl('', Validators.required));
      }
    }
    if (localStorage.getItem('instagram-code')) {
      requestAnimationFrame(() => {
        this.currentSlide = 2;
        const form_filed = JSON.parse(localStorage.getItem('form-filed'));
        for (const feild of feildsData) {
          if (feild.field === 'gender') {
            this.funnelForm.get(feild.field).setValue(form_filed['gender']);
            this.gender = form_filed['gender'];
          }
          if (feild.field === 'instagram') {
            // this will fillup what user has entered instead we should get insta username from API !
            this.funnelForm.get(feild.field).setValue(form_filed['instagram']);
          }
        }
      });
    }
    if (this._route.snapshot.queryParams['error']) {
      requestAnimationFrame(() => {
          this.currentSlide = 1;
          if (this._route.snapshot.queryParams['error'] && this._route.snapshot.queryParams['instacode']) {
            this.errorService.error([{key: 'REGISTER.INSTAGRAMERROR'}], ['user', 'funnel-questions-interest']);
          } else {
            this.errorService.error([{key: 'REGISTER.INSTAGRAMCODE'}], ['user', 'funnel-questions-interest']);
          }
          const form_filed = JSON.parse(localStorage.getItem('form-filed'));
          for (const feild of feildsData) {
            if (feild.field === 'gender') {
              this.funnelForm.get(feild.field).setValue(form_filed['gender']);
              this.gender = form_filed['gender'];
            }
            if (feild.field === 'instagram') {
              this.funnelForm.get(feild.field).setValue(form_filed['instagram']);
              this.gender = form_filed['gender'];

            }
          }
        }
      );
    }

  }

  saveValueToCookie(formValues) {
    const question_cookie = {};
    Object.keys(formValues).forEach((val, key) => {
      question_cookie[val] = formValues[val];
    });
    localStorage.setItem('form-filed', JSON.stringify(question_cookie));
    this._cookiesService.setCookie('question_cookie', JSON.stringify(question_cookie), 0.5);
  }

  changeSlide(action) {
    if (action === 'prev') {
      if (this.currentSlide > 0) {
        this.currentSlide -= 1;
      }
    } else if (action === 'skip') {
      if (this.currentSlide !== (this.funnelQuestions.length - 1)) {
        this.currentSlide += 1;
      }
    } else if (action === 'next') {
      if (this.currentSlide !== (this.funnelQuestions.length - 1)) {
        if (this.currentSlide === 1) {
          if (environment.instagramLogin) {
            const type = 'instagram';
            if (localStorage.getItem('instagram-code')) {
              this.currentSlide += 1;
            } else {
              this.getSocialAppUrl(type);
            }
          } else {
            this.currentSlide += 1;
          }
        } else {
          this.currentSlide += 1;
        }

      }
    } else if (action === 'submit') {
      const tempArray: any[] = [];
      const interestValues = this.funnelForm.get('interests').value;
      interestValues.forEach((insertedValue, index) => {
        if (insertedValue) {
          tempArray.push(this.interestArray[index]['value']);
        }
      });
      this.funnelForm.value['interests'] = tempArray;
      this.saveValueToCookie(this.funnelForm.value);
      this._router.navigate(['/register']);
    }
  }

  getMaleSrc(field_name) {
    if (this.funnelForm.get(field_name) && (this.funnelForm.get(field_name).value === 'male')) {
      return 'assets/images/MaleSelected.svg';
    } else {
      return 'assets/images/Male.svg';
    }
  }

  getFemaleSrc(field_name) {
    if (this.funnelForm.get(field_name) && (this.funnelForm.get(field_name).value === 'female')) {
      return 'assets/images/FemaleSelected.svg';
    } else {
      return 'assets/images/Female.svg';
    }
  }

  setSelectedGender(value) {
    this.selectedGender.next(value);
  }

  getSelectedGender(): Observable<any> {
    return this.selectedGender.asObservable();
  }

  getGenderClass(append) {
    if (this.gender === '') {
      return '';
    }
    return this.gender + append;
  }

  checkIfMaximumChecked(field, index) {
    const interests = this.funnelForm.get(field).value;
    if (interests.filter(interest => interest).length > 5) {
      this.errorService.warn([{key: 'REGISTER.MAX_INTERESTS'}], ['user', 'funnel-questions-interest']);
      interests[index] = false;
      this.funnelForm.get(field).setValue(interests);
    } else if (interests.filter(interest => interest).length < 1) {
      this.isValidNumberOfInterestsSelected = false;
    } else {
      this.isValidNumberOfInterestsSelected = true;
    }
  }

  checkIfFieldIsValid() {
    if (this.currentSlide === 0) {
      return this.funnelForm.get('gender').invalid;
    }
    if (this.currentSlide === 1) {
      return this.funnelForm.get('instagram').invalid;
    }
    if (this.currentSlide === 2) {
      return this.funnelForm.get('interests').invalid;
    }
  }

  getSocialAppUrl(type: string) {
    this.saveValueToCookie(this.funnelForm.value);
    this._userService.getSocialAppUrl(type).subscribe(res => {
      if (res.url) {
        const insta_url = res.url + this.base_url + '/instagram';
        localStorage.setItem('insta-url', insta_url);
        localStorage.setItem('base_url', this._router.url);
        window.open(insta_url, '_self');
      }
    });
  }

}
