import {NgModule, OnInit} from '@angular/core';
import {Routes, RouterModule, Router} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/user/components/login/login.component';
import {RegisterComponent} from './views/user/components/register/register.component';
// import { ChangePasswordComponent } from './views/user/components/change_password/change_password.component';
import {ForgotPasswordComponent} from './views/user/components/forgot_password/forgot_password.component';
import {ActivateUserComponent} from './views/user/components/activate_user/activate_user.component';
import {ResetPasswordComponent} from './views/user/components/reset_password/reset_password.component';
import {AuthGuard} from './guards/auth.guard';
import {FunnelQuestionsComponent} from './views/user/components/funnel-questions/funnel-questions.component';
import {InstagramComponent} from './views/instagram/instagram.component';
import {EmailConfirmationComponent} from './views/user/components/email-confirmation/email-confirmation.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'page-not-found',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'instagram',
    component: InstagramComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'funnel-questions',
    component: FunnelQuestionsComponent,
    data: {
      title: 'Funnel Question'
    }
  },

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'forgot Password'
    }
  },
  {
    path: 'influencer/user/activate/:token',
    component: ActivateUserComponent,
    data: {
      title: 'Activate User Page'
    }
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    data: {
      title: 'Activate User Page'
    }
  },
  {
    path: 'email-confirmation',
    component: EmailConfirmationComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'user',
        canActivate: [AuthGuard],
        loadChildren: './views/user/user.module#UserModule'
      },
      {
        path: 'news',
        canActivate: [AuthGuard],
        loadChildren: './views/news/news.module#NewsModule'
      },
      {
        path: 'campaign',
        canActivate: [AuthGuard],
        loadChildren: './views/campaign/campaign.module#CampaignModule'
      },
      {
        path: 'messenger',
        canActivate: [AuthGuard],
        loadChildren: './messenger/messenger.module#MessengerModule'
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: './views/profile/profile.module#ProfileModule'
      },
      {
        path: 'stats',
        canActivate: [AuthGuard],
        loadChildren: './views/stats/stats.module#StatsModule'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'payment',
        canActivate: [AuthGuard],
        loadChildren: './views/payment/payment.module#PaymentModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor() {
  }
}
