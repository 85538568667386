import { Deserializable } from './deserializable.model';

export class Payment implements Deserializable {
  bic: string;
  iban: string;
  influencer_user_id?: number;
  paypal: string;
  preferred: number;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
