import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../services';

@Component({
    templateUrl: 'forgot_password.component.html',
    styleUrls: [
        '../login/general.login.component.scss',
        './forgot_password.component.scss',
    ]
})
export class ForgotPasswordComponent {

    user: any = {};
    forgotPasswordForm: FormGroup;
    isSendEmail = false;
    api_error = {};

    constructor(public _router: Router, public fb: FormBuilder, public _user_service: UserService) {

        this.forgotPasswordForm = fb.group({
            'email': ['', [Validators.required, Validators.email]]
        });
    }

    forgotPassword = (user_values) => {
        this.api_error = {};
        this._user_service.forgotPassword(user_values).subscribe(res => {
                if (res['status'] === true) {
                    this.isSendEmail = true;
                }
            },
            error => {
                if (error['error']['status'] === false) {
                    this.api_error['email'] = ['email does not exist.'];
                }
                setTimeout(() => {
                    this.api_error = {};
                }, 5000);


            });
    }

}
