import { Deserializable } from './deserializable.model';

export class Question implements Deserializable {
  field: string;
  question: number;
  answer: Array<any>;

  constructor() { }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
