import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'validation-message',
  template: `<alert type="danger" class="error-span" *ngIf="errorMessage">{{errorMessage}}</alert>`,
  styleUrls: ['./validation-message.component.scss']
})

export class ValidationMessagesComponent {
  @Input() control: FormControl;
  // tslint:disable-next-line:no-input-rename
  @Input('validationname') name: string;
  constructor() { }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName], this.name);
      }
    }
    return false;
  }
}
