export const navItems = [
  {
    name: 'SIDEBAR.DASHBOARD',
    url: '/dashboard',
    icon: 'fa fa-tachometer'
  },
  {
    name: 'SIDEBAR.CAMPAIGN',
    url: '/campaign',
    icon: 'icon-compass'
  },
  {
    name: 'SIDEBAR.MESSENGER',
    url: '/messenger',
    icon: 'icon-envelope-letter'
  },
  {
    name: 'SIDEBAR.PROFILE',
    url: '/profile',
    icon: 'icon-earphones'
  },
  {
    name: 'SIDEBAR.PAYMENT',
    url: '/payment',
    icon: 'icon-credit-card'
  }
];
