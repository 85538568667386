import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';

import { Campaign, Common } from '../models/index';
import { LoaderService } from './loader.service';

@Injectable()
export class DashboardService {
  private static campaignsSubject = new Subject<any>();

  constructor(private http: HttpClient) {

  }

  refreshCampaigns(): void {
    this.http.get<any>(`${environment.API}/influencer/dashboard/campaigns`).subscribe(
      (campaigns: any[]) => {
        DashboardService.campaignsSubject.next(
          campaigns.map((campaign: any) => new Campaign().deserialize(campaign))
        );
      },
      error => {
        DashboardService.campaignsSubject.next({error: true});
      });
  }

  getCampaigns(): Observable<Campaign[]> {
    return DashboardService.campaignsSubject;
  }

  getTypes() {
    return this.http.get<Common[]>(`${environment.API}/advertiser/campaign/types`);
  }

  getPayments() {
    return this.http.get<Common[]>(`${environment.API}/advertiser/campaign/payments`);
  }

  getChannels() {
    return this.http.get<Common[]>(`${environment.API}/advertiser/campaign/channels`);
  }

  getQuestions(id = 1) {
    return this.http.get<Common[]>(`${environment.API}/influencer/dashboard/getquestions/${id}`);
  }

  saveAnswer(answer) {
    return this.http.post<Common[]>(`${environment.API}/influencer/dashboard/answerquestion`, answer);
  }

  getNotification() {
    return this.http.get<Common[]>(`${environment.API}/notification/list`);
  }

  deleteNotification(id) {
    return this.http.delete(`${environment.API}/notification/delete/${id}`);
  }

}
