import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss']
})
export class WordCloudComponent implements OnInit {

  @ViewChild('wordWrapper', {static: true}) wordWrapper;
  @Input() words: Word[] = [];

  outOfBoundsCount: any;
  width: any;
  height: any;
  centerX: any;
  centerY: any;

  borders: any;
  takenSpots: any;
  lastCollisionSpot: any;

  ngOnInit(): void {
    this.setupCustomScopeVariables();
    this.formWordCloud();
  }

  setupCustomScopeVariables() {
    this.width = this.wordWrapper.nativeElement.offsetWidth;
    this.height = this.wordWrapper.nativeElement.offsetHeight;

    this.centerX = this.width / 2;
    this.centerY = this.height / 2;
    this.borders = {
      left: this.centerX - this.width / 2,
      right: this.centerX + this.width / 2,
      bottom: this.centerY - this.height / 2,
      top: this.centerY + this.height / 2
    };

    this.outOfBoundsCount = 0;
    this.takenSpots = [];
    this.lastCollisionSpot = -1;
  }


  formWordCloud() {
    if (this.words.length > 0) {
      this.shuffleWords();
      this.determineWordPositions();
    }
  }

  shuffleWords() {
    for (let i = this.words.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = this.words[i];
      this.words[i] = this.words[j];
      this.words[j] = temp;
    }
  }

  determineWordPositions() {
    setTimeout(() => {
      const children = this.wordWrapper.nativeElement.querySelectorAll('span.word');
      const length = children.length;
      for (let i = 0; i < length; i++) {
        this.setWordSpanPosition(children[i]);
        if ((i + 1) === length) {
          this.setHidden();
        }
      }
    }, 1000);
  }

  setHidden() {
    const children = this.wordWrapper.nativeElement.querySelectorAll('span.word');
    const length = children.length;
    for (let i = 0; i < length; i++) {
      if (children[i].classList.contains('span-hidden')) {
        children[i].classList.add('span-display');
      }
    }
  }

  setWordSpanPosition(span) {

    let spot = this.setupDefaultSpot(span);
    let angleMultiplier = 0;

    while (this.spotNotUsable(spot) && this.outOfBoundsCount < 50) {
      spot = this.moveSpotOnSpiral(spot, angleMultiplier);
      angleMultiplier += 1;
    }

    if (this.outOfBoundsCount < 50) {
      this.takenSpots.push(spot);
      this.moveSpanToEmptySpot(span, spot.startX, spot.startY);
    }
    this.outOfBoundsCount = 0;
  }

  setupDefaultSpot(span) {
    const width = span.offsetWidth;
    // const width = 40;
    const height = parseInt(window.getComputedStyle(span).lineHeight, 10);
    // const height = parseInt(span.offsetWidth,10);
    return {
      width: width,
      height: height,
      startX: this.centerX - width / 2,
      startY: this.centerY - height / 2,
      endX: this.centerX + width / 2,
      endY: this.centerY + height / 2
    };
  }

  moveSpotOnSpiral(spot, angleMultiplier) {
    const angle = angleMultiplier * 0.1;
    spot.startX = this.centerX + (1.5 * angle) * Math.cos(angle) - (spot.width / 2);
    spot.startY = this.centerY + angle * Math.sin(angle) - (spot.height / 2);
    spot.endX = spot.startX + spot.width;
    spot.endY = spot.startY + spot.height;
    return spot;
  }


  spotNotUsable(spot) {

    if (this.spotOutOfBounds(spot)) {
      return true;
    }

    if (this.lastCollisionSpot !== -1 && this.collisionDetected(spot, this.takenSpots[this.lastCollisionSpot])) {
      return true;
    }

    for (let i = 0; i < this.takenSpots.length; i++) {
      if (i !== this.lastCollisionSpot && this.collisionDetected(spot, this.takenSpots[i])) {
        this.lastCollisionSpot = i;
        return true;
      }
    }

    this.lastCollisionSpot = -1;
    return false;
  }

  spotOutOfBounds(spot) {
    if (spot.startX < this.borders.left ||
      spot.endX > this.borders.right ||
      spot.startY < this.borders.bottom ||
      spot.endY > this.borders.top) {
      this.outOfBoundsCount++;
      return true;
    } else {
      return false;
    }
  }

  collisionDetected(spot, takenSpot) {
    if (spot.startX > takenSpot.endX || spot.endX < takenSpot.startX) {
      return false;
    }

    return !(spot.startY > takenSpot.endY || spot.endY < takenSpot.startY);
  }

  moveSpanToEmptySpot(span, startX, startY) {
    const style = 'position: absolute; left:' + startX + 'px; top: ' + startY + 'px;';
    span.setAttribute('style', style);
    span.classList.remove('span-hidden');
  }


}

export interface Word {
  title: string;
  size: number;
  color: string;
}
