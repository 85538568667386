import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService, UserService} from './services';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet><toaster-container></toaster-container>'
})
export class AppComponent implements OnInit {
  // isPageRefreshed: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private _lang: LanguageService,
    private _language: LanguageService,
    private _userService: UserService) {
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    _lang.values.subscribe((response: any) => {
      translate.setDefaultLang(`${response.default_language}`);
    });
  }

  ngOnInit() {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this._userService.getAllRequiredData().then(resposne => {
      this._lang.addRouteToNavigation();
    });
  }

}
