import { AbstractControl } from '@angular/forms';

/**
 * @usage Confirm Password validator for form
 */

export class ConfirmPasswordValidation {

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('passwordRepeat').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('passwordRepeat').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }
}
