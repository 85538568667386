import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidation } from '../../../../validators/confirm-password.validator';
import { UserService } from '../../../../services';
import { ErrorService } from '../../../../services/error.service';

@Component({
  templateUrl: 'change_password.component.html',
  styleUrls: ['./change_password.component.scss']
})
export class ChangePasswordComponent {

  user: any = {};
  changePasswordForm: FormGroup;
  isPasswordChanged = false;
  api_error = {};

  constructor(public _router: Router, public fb: FormBuilder, public _user_service: UserService, private errorService: ErrorService) {

    this.changePasswordForm = fb.group({
      'password': ['', [Validators.required, Validators.minLength(6)]],
      'passwordRepeat': ['', [Validators.required, Validators.minLength(6)]]
    },
      {
        validator: ConfirmPasswordValidation.MatchPassword
      });
  }

  changePassword = (user_values) => {
    this.api_error = {};
    this._user_service.changePassword(user_values).subscribe(res => {
      if (res['status'] === true) {
        this.api_error = {};
        this.isPasswordChanged = true;
        setTimeout(() => {
          this._router.navigate(['/base']);
        }, 1500);
      } else {
        this._router.navigate(['/login']);
      }
      //            setTimeout(() => {
      //                this._router.navigate(['/login']);
      //            }, 5000);
    },
      error => {
        const messages = error.error.messages;
        this.api_error = messages;
        this.errorService.error([error.error.messages], ['user', 'change_password']);
        // this._router.navigate(['/login']);
        //                setTimeout(() => {
        //                    this._router.navigate(['/login']);
        //                }, 5000);
      });
  }


}
