import { Deserializable } from './deserializable.model';

export class News implements Deserializable {
  id: number;
  message: string;
  news_category_id: number;
  subject: number;
  type: number;
  files: object;
  image_files?: object;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
