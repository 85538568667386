import { Deserializable } from './deserializable.model';

export class InfluencerPayment implements Deserializable {
  advertiser_id: number;
  amount_gross: number;
  amount_net: string;
  amount_vat: number;
  campaign_id: number;
  campaign_name: string;
  invoice: any;
  paid: any;
  vat: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
