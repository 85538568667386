export const environment = {
  production: false,
 // API: 'https://api.staging.yimify.io/v1',
  API: 'https://api.bistduanders.de/v1',
  websocket: 'wss://api.bistduanders.de:3000',
  bugsnagAPIKey: '57ef8c6cf591fb50de57470b275584a7',
  Default_language: 'de',
  TERMS: 'https://yimify.com/nutzungsbedingungen-influencer/',
  PRIVACY: 'https://yimify.com/datenschutz/',
  SERVICE_AGREEMENT: 'https://www.yimify.com/nutzungsbedingungen-influencer#stripe',
  MESSAGE_REFRESH_INTERVAL: 2000,
  instagramLogin: false,
};
