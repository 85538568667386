import { Deserializable } from './deserializable.model';

export class ProfileData implements Deserializable {
  id?: number;
  firstname: string;
  lastname: string;
  biography: string;

  constructor() { }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
