import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/catch';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

/*
 Interceptor for every api request to append Authrization to header
*/

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _user: UserService, private _router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = request.clone({
      setHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    let headers = request.headers;
    if (headers.get('noToken') === 'noToken') {
      headers = headers.delete('Authorization').delete('noToken');
    }
    const newReq = request.clone({headers: headers});

    // return next.handle(request);
    return next.handle(newReq).catch((error) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this._user.logout();
          this._router.navigate(['/login']);
        }
      }
      return throwError(error);
    });
  }

}
