import { Deserializable } from './deserializable.model';

export class Social implements Deserializable {
  anchor: string;
  blog: string;
  facebook: string;
  influencer_user_id?: number;
  instagram: string;
  linkedin: string;
  musically: string;
  snapchat: string;
  social_id: number;
  twitch: string;
  twitter: string;
  website: string;
  xing: string;
  youtube: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
