import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { News, Common } from '../models';
import { map } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class NewsService {
  private static news: BehaviorSubject<News[]> = new BehaviorSubject([]);
  private static categories: BehaviorSubject<Common[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient) { }

  private getNewsForType(category): Observable<News[]> {
    return this.http.get<News[]>(`${environment.API}/news/list?category=${category}&type=1`);
  }

  private refreshNewsForType(category): void {
    requestAnimationFrame(() => {
      LoaderService.show();
      this.getNewsForType(category).subscribe(news => {
        LoaderService.hide();
        NewsService.news.next([...news.map(v => new News().deserialize(v))]);
      }, error => {
        LoaderService.hide();
      });
    });
  }

  getNewsCategoriesData(): Observable<Common[]> {
    return this.http.get<Common[]>(`${environment.API}/news/categories`);
  }

  refreshNewsCategories(): void {
    this.getNewsCategoriesData().subscribe(categories => {
      NewsService.categories.next(categories);
    });
  }

  getNewsCategories(): Observable<Common[]> {
    this.refreshNewsCategories();
    return NewsService.categories.asObservable();
  }

  getNews = (category) => {
    this.refreshNewsForType(category);
    return NewsService.news.asObservable().pipe(
      map(campaigns => campaigns.filter(c => (category === '' ? true : c.news_category_id === category))
      )
    );
  }

}
