import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'yimifyImage'})
export class YimifyImagePipe implements PipeTransform {

  transform(imagePath: any, defaultImage = '/assets/images/user-default.png'): any {
    if (imagePath && (imagePath.includes('https://') || imagePath.includes('http://'))) {
      return imagePath;
    } else {
      return defaultImage;
    }
  }

}
