import { Deserializable } from './deserializable.model';

/**
 * Used For
 * 1. Campaign Types
 * 2. Campaign Payments
 * 3. Campaign Channel
 * 4. User's Campaign Status [My Status]
 * 5. Campaign Status
 */
export class Common implements Deserializable {
  id: number;
  name: string;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
