import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService, LanguageService, ProfileService } from '../../../services';
import { Router } from '@angular/router';
import 'rxjs/add/operator/takeWhile';

@Component({
  selector: 'yimify-app-header',
  templateUrl: './yimify-app-header.component.html',
  styleUrls: ['./yimify-app-header.component.scss']
})



export class YimifyAppHeaderComponent implements OnInit, OnDestroy {

  alive: boolean = true;
  profile_img: string = '';

  constructor(public _user_service: UserService, public _router: Router, private _lang: LanguageService,
    private _profile: ProfileService) { }
  selected_language: string = '';

  ngOnInit() {
    this.subscribeProfileServiceData();
    this.profile_img = this._profile.getProfileImageUrl();
    if (this.profile_img === null || this.profile_img === '') {
      this.profile_img = 'assets/img/avatars/user-default.png';
    }
    this._lang.values
      .takeWhile(() => this.alive)
      .subscribe((response: any) => {
        this.selected_language = response.default_language;
      });
  }

  subscribeProfileServiceData = () => {
    this._profile.profile_subject
      .takeWhile(() => this.alive)
      .subscribe(
        response => {
          if (response['error']) {
            this.profile_img = 'assets/img/avatars/user-default.png';
          } else {
            if (response['data']['upload_image']) {
              if (response['setUserProfile']) {
                if (response['data']['upload_image'] === null && response['data']['upload_image'] === '') {
                  this.profile_img = 'assets/img/avatars/user-default.png';
                } else {
                  this.profile_img = response['data']['upload_image'];
                }
              }
            } else {
              this.profile_img = 'assets/img/avatars/user-default.png';
            }
          }
        }
      );
  }

  change_language = () => {
    this._lang.changeUserLanguage(this.selected_language);
  }

  logout() {
    this._user_service.logout();
    this._router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.alive = false;
  }


}
