import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Common, Campaign } from '../models/index';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, toArray, mergeAll } from 'rxjs/operators';


@Injectable()
export class CampaignService {
  private static campaignList: BehaviorSubject<Campaign[]> = new BehaviorSubject([]);
  public dashboard: boolean;

  constructor(private http: HttpClient) {
  }

  private getCampaignListForStatus(status, myStatus): Observable<Campaign[]> {
    return this.http.get<Campaign[]>(`${environment.API}/influencer/campaign/list?status=${status}&myStatus=${myStatus}`);
  }

  private refreshCampaignListForStatus(status, myStatus): void {
    this.getCampaignListForStatus(status, myStatus).subscribe(campaigns => {
      CampaignService.campaignList.next([
        ...CampaignService.campaignList.getValue().filter(c => c.statusId !== myStatus),
        ...campaigns.map(v => new Campaign().deserialize({...v, statusId: myStatus}))
      ]);
    });
  }

  getCampaignList(status, myStatus) {
    this.refreshCampaignListForStatus(status, myStatus);
    return CampaignService.campaignList.asObservable().pipe(
      map(campaigns => campaigns.filter(campagin => campagin.statusId === myStatus))
    );
  }


  getCampaignStatus() {
    return this.http.get<Common[]>(`${environment.API}/influencer/campaign/status`);
  }

  getMyCampaignStatus() {
    return this.http.get<Common[]>(`${environment.API}/influencer/campaign/mystatus`);
  }

  getCampaignDetails(id) {
    return this.http.get<Campaign>(`${environment.API}/influencer/campaign/detail/${id}`);
  }

  acceptCampaign(campaign) {
    return this.http.post<any>(`${environment.API}/influencer/campaign/accept`, campaign);
  }

  applyCampaign(campaign) {
    return this.http.post<any>(`${environment.API}/influencer/campaign/apply`, campaign);
  }

  rejectCampaign(campaign) {
    return this.http.post<any>(`${environment.API}/influencer/campaign/reject`, campaign);
  }

  notApplyCampaign(campaign) {
    return this.http.post<any>(`${environment.API}/influencer/campaign/notapply`, campaign);
  }

  uploadTrackingUrls(campaign) {
    return this.http.post<any>(`${environment.API}/influencer/tracking`, campaign);
  }

  getUploadTrackingUrls(campaign) {
    return this.http.get<any>(`${environment.API}/influencer/tracking?campaignId=` + campaign);
  }

  removeTrackingUrl(id) {
    return this.http.delete<any>(`${environment.API}/influencer/tracking/delete/` + id);
  }
}
