import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidation } from '../../../../validators/confirm-password.validator';
import { UserService } from '../../../../services';
import { ErrorService } from '../../../../services/error.service';

@Component({
  templateUrl: 'reset_password.component.html',
  styleUrls: ['./reset_password.component.scss']
})
export class ResetPasswordComponent {

  resetPasswordForm: FormGroup;
  user: any = {};
  isChanged: boolean = false;
  api_error = {};
  token: string = '';

  constructor(
    public _router: Router, public fb: FormBuilder, public _user_service: UserService,
    public _route: ActivatedRoute, private errorService: ErrorService
  ) {
    this.resetPasswordForm = fb.group({
      'password': ['', [Validators.required, Validators.minLength(6)]],
      'passwordRepeat': ['', [Validators.required, Validators.minLength(6)]]
    },
      {
        validator: ConfirmPasswordValidation.MatchPassword
      });

    this._route.params.subscribe(params => {
      if (params['token']) {
        this.token = params['token'];
      } else {
        this._router.navigate(['/login']);
      }
    });
  }


  resetPassword = (user_values) => {
    user_values['token'] = this.token;
    this.api_error = {};
    this._user_service.resetPassword(user_values).subscribe(res => {
      this.isChanged = true;
      setTimeout(() => {
        this._router.navigate(['/login']);
      }, 5000);

    },
      error => {
        const messages = error.error.messages;
        this.api_error = messages;
        this.errorService.error([messages], ['user', 'reset_password']);
        if (this.api_error['token']) {
          setTimeout(() => {
            this._router.navigate(['/login']);
          }, 5000);
        }
      });
  }

}
