import { Deserializable } from './deserializable.model';
import { Message } from './message.model';

export class MessangerCampaign implements Deserializable {
  campaign_id: number;
  name: string;
  image: string;
  new?: number;

  messages?: Message[];

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export interface IMessangerCampaignHashMap {
  [campaign_id: number]: MessangerCampaign;
}
