import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, of, Observable, BehaviorSubject} from 'rxjs';
import {catchError, retry, filter} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {CookiesService} from './cookies.service';
import {ProfileService} from './profile.service';
import {CampaignService} from './campaign.service';
import {Common, Question} from '../models/index';

@Injectable()
export class UserService {

  public readonly tokenSubject = new BehaviorSubject<string>(localStorage.getItem('token'));

  public get Token() {
    return this.tokenSubject.pipe(
      filter(v => v === this.tokenSubject.getValue())
    );
  }

  constructor(private http: HttpClient, private inj: Injector) { }

  signup = (user) => {
    return this.http.post<any>(environment.API + '/influencer/user/signup', user);
  }

  activateAccount = (user) => {
    return this.http.post<any>(environment.API + '/influencer/user/activate', user);
  }

  login = (user) => {
    return this.http.post<any>(environment.API + '/influencer/user/login', user);
  }

  changePassword = (user) => {
    return this.http.post<any>(environment.API + '/influencer/user/changepassword', user);
  }

  forgotPassword = (user) => {
    return this.http.post<any>(environment.API + '/influencer/user/forgotpassword', user);
  }

  resetPassword = (user) => {
    return this.http.post<any>(environment.API + '/influencer/user/resetpassword', user);
  }

  logout = () => {
    const _cookie = this.inj.get(CookiesService);
    const _profile = this.inj.get(ProfileService);
    _cookie.deleteCookie('yimify_news_category');
    _profile.emptyProfile();
    localStorage.removeItem('authenticated');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('globalStatus');
    localStorage.removeItem('myStatus');
    localStorage.removeItem('open_dashboar_model');
    localStorage.removeItem('stats_interest_icons');
    localStorage.removeItem('stats');

    this.tokenSubject.next(null);
  }

  getAllRequiredData() {
    const _campaign = this.inj.get(CampaignService);
    const myStatus: Observable<Common[]> = _campaign.getMyCampaignStatus();
    const globalStatus: Observable<Common[]> = _campaign.getCampaignStatus();
    let isAllResponse: boolean = false;
    const promise = new Promise<boolean>((resolve, reject) => {
      if (localStorage.getItem('authenticated') !== null && localStorage.getItem('authenticated') === '1') {
        if (!localStorage.getItem('myStatus') && !localStorage.getItem('globalStatus')) {
          forkJoin([myStatus, globalStatus])
            .pipe(catchError(error => of(error)), retry(3))
            .subscribe(results => {
              isAllResponse = true;
              localStorage.setItem('myStatus', JSON.stringify(results[0]));
              localStorage.setItem('globalStatus', JSON.stringify(results[1]));
              resolve(true);
            });
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
    return promise;
  }

  getFunnelQuestions() {
    return this.http.get<Question[]>(`${environment.API}/influencer/user/loginfunnel`);
  }

  getSocialAppUrl(type: string) {
    return this.http.get<any>(`${environment.API}/influencer/social/app?type=${type}`);
  }

  setSocialCode = (socialdata) => {
    return this.http.post<any>(environment.API + '/influencer/social/code', socialdata);
  }
}
