import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, NgModule} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';

import {PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// Import bugsnag-js and bugsnag-angular
import BugsnagErrorHandler from 'bugsnag-angular';
import bugsnag from 'bugsnag-js';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';


import {AppComponent} from './app.component';
// Import containers
import {DefaultLayoutComponent} from './containers';
import {YimifyAppHeaderComponent} from './containers/default-layout/header/yimify-app-header.component';
import {YimifyAppSideBarComponent} from './containers/default-layout/sidebar/yimify-app-sidebar.component';
import {YimifyAppSidebarProfileComponent} from './containers/default-layout/sidebar/profile/yimify-app-sidebar-profile.component';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {
  ActivateUserComponent,
  ForgotPasswordComponent,
  LoginComponent,
  RegisterComponent,
  ResetPasswordComponent
} from './views/user/components';
import {
  CampaignService,
  CookiesService,
  DashboardService,
  ErrorService,
  HelperService,
  LanguageService,
  LoaderService,
  PaymentService,
  ProfileService,
  StatsService,
  TooltipService,
  UserService,
  ValidationService
} from './services';
// Interceptor
import {TokenInterceptor} from './interceptor/token.interceptor';
import {SharedCustomModule} from './shared/shared.module';
// translation module
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import {AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule} from '@coreui/angular';
// Import routing module
import {AppRoutingModule} from './app.routing';
// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {AuthGuard} from './guards/auth.guard';
import {AccordionModule, CarouselModule} from 'ngx-bootstrap';
import {FunnelQuestionsComponent} from './views/user/components/funnel-questions/funnel-questions.component';
import {ANIMATION_TYPES, LoadingModule} from 'ngx-loading';
import {InstagramComponent} from './views/instagram/instagram.component';
import {EmailConfirmationComponent} from './views/user/components/email-confirmation/email-confirmation.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const APP_CONTAINERS = [
  DefaultLayoutComponent,
  YimifyAppHeaderComponent,
  YimifyAppSideBarComponent,
  YimifyAppSidebarProfileComponent
];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const providers: any[] = [
  AuthGuard,
  UserService,
  ValidationService,
  CookiesService,
  LanguageService,
  ProfileService,
  ToasterService,
  CampaignService,
  DashboardService,
  StatsService,
  LoaderService,
  ErrorService,
  PaymentService,
  HelperService,
  TooltipService,
  {provide: LocationStrategy, useClass: PathLocationStrategy},
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
];

if (!!environment['bugsnagAPIKey']) {
  const bugsnagClient = bugsnag(environment['bugsnagAPIKey']);
  // create a factory which will return the bugsnag error handler
  const errorHandlerFactory = () => {
    return new BugsnagErrorHandler(bugsnagClient);
  };

  providers.push({provide: ErrorHandler, useFactory: errorHandlerFactory});
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    SharedCustomModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    ToasterModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    LoadingModule,
    LoadingModule.forRoot({
      animationType: ANIMATION_TYPES.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#000000',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
      fullScreenBackdrop: true
    })
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ActivateUserComponent,
    // ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    // CampaignComponent
    FunnelQuestionsComponent,
    InstagramComponent,
    EmailConfirmationComponent
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule {

}
