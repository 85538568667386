import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';
import {Location} from '@angular/common';
import {LoaderService, UserService} from '../../services';

@Component({
  templateUrl: './instagram.component.html'
})
export class InstagramComponent implements OnInit {
  instagramcode;

  constructor(private _router: Router, private _route: ActivatedRoute,
              private userservice: UserService,
              private location: Location) {
  }

  ngOnInit() {
    const url = localStorage.getItem('base_url');
    this.instagramcode = this._route.snapshot.queryParamMap.get('code');
    if (this.instagramcode) {
      const socialdata = {};
      socialdata['portal'] = 'instagram';
      socialdata['code'] = this.instagramcode;
      socialdata['redirect_uri'] = localStorage.getItem('insta-url');

      this.userservice.setSocialCode(socialdata).subscribe(res => {
          if (res.status === true) {
            localStorage.setItem('instagram-code', this.instagramcode);
            // its other component/modules's responsibility to remove  instagram-code from localstorage after getting and setting to APIs
            this._router.navigate([url]);
          }
        },
        error => {
          this._router.navigate([url], {queryParams: {error: true}});
        });
    } else {
      this._router.navigate([url], {queryParams: {error: true, instacode: true}});
    }
  }
}
