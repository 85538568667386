import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TooltipService} from '../../../services';

@Component({
  selector: 'app-tooltip',
  template: `
    <i *ngIf="tooltip" [classList]="iconClass" [tooltip]="tooltip" adaptivePosition="false"
       [placement]="placement" container="body"></i>
  `,
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy, OnChanges {

  @Input() key;
  @Input() placement = 'right';
  @Input() iconClass = 'fa fa-info-circle';

  public tooltip;
  private isAlive = true;

  constructor(private _tooltipService: TooltipService) {
  }

  ngOnInit() {
    this._tooltipService.getTooltipChangeSubscription()
      .takeWhile(() => this.isAlive)
      .subscribe(() => {
        this.getTooltip();
      });

    this.getTooltip();
  }

  getTooltip() {
    this._tooltipService.getTooltip(this.key).then((tooltip) => {
      this.tooltip = tooltip;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTooltip();
  }

  ngOnDestroy(): void {
    this.isAlive = false;
  }

}
