import { Injectable } from '@angular/core';

@Injectable()
export class HelperService {

  static isVisibleInView(el, parent?: HTMLElement) {
    const childRect = el.getBoundingClientRect();
    const parentRect = parent || el.parentNode;

    if (childRect.top <= parentRect.bottom) {
      return false;
    }
    if ((childRect.top + childRect.height) <= parentRect.top) {
      return false;
    }
    return true;
  }

  static isVisible(el, parent?: HTMLElement) {
    const { top, height } = el.getBoundingClientRect();
    el = parent || el.parentNode;
    do {
      const rect = el.getBoundingClientRect();
      if (top <= rect.bottom === false) {
        return false;
      }
      if ((top + height) <= rect.top) {
        return false;
      }
      el = el.parentNode;
    } while (el !== document.body);
    return top <= document.documentElement.clientHeight;
  }

}
