import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

/*
 * @usage Auth Guard check if authenticated or not. If authenticated then
 *        continue otherwise redirect to login.
 */

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _router: Router) {}

    /**
     * @usage check user is authenticated on not
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        // tslint:disable-next-line:radix
        const authenticated = parseInt(localStorage.getItem('authenticated'));
        if (authenticated === 1) {
            return true;
        }
        this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
