import { Deserializable } from './deserializable.model';

export class Address implements Deserializable {
  country: string;
  id?: number;
  location: string;
  mobile: string;
  number: string;
  phone: string;
  residence: string;
  street: string;
  zipcode: string;

  constructor() { }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
