import { Deserializable } from './deserializable.model';

export interface TrackingUrl {
  id: string;
  link: string;
}

export class Campaign implements Deserializable {
  id: number;
  name: string;
  type: number;
  status: number;
  payment: number;
  fix_price = null;
  application: number;
  application_start = null;
  application_end = null;
  execution_start: number;
  execution_end: number;
  description: string;
  instructions: string;
  channel: number;
  statusId: number = -1;
  label: Object;
  can: Object;
  instructions_header: string;
  tracking_urls: TrackingUrl[];

  constructor() { }

  deserialize(input: any) {
    Object.assign(this, input);
    // this.statusId = this.status;
    return this;
  }
}
