import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../services';
import { ErrorService } from '../../../../services/error.service';

@Component({
  templateUrl: 'activate_user.component.html',
  styleUrls: ['./activate_user.component.scss']
})
export class ActivateUserComponent {

  user: any = {};
  isActivated = false;
  api_error = {};

  constructor(
    public _router: Router, public _user_service: UserService, public _route: ActivatedRoute, private errorService: ErrorService
  ) {
    this._route.params.subscribe(params => {
      if (params['token']) {
        this.activateAccount({ token: params['token'] });
      }
    });
  }
  activateAccount = (user_values) => {
    this.api_error = {};
    this._user_service.activateAccount(user_values).subscribe(res => {
      this.isActivated = true;
      setTimeout(() => {
        this._router.navigate(['/login']);
      }, 3000);

    },
      error => {
        const messages = error.error.messages;
        this.api_error = messages;
        this.errorService.error([error.error.messages], ['user', 'activate']);
        setTimeout(() => {
          this._router.navigate(['/login']);
        }, 3000);


      });
  }

}
