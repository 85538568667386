import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {navItems} from '../../../_nav';
import {LanguageService} from './../../../services';

const newLocal = true;

@Component({
  selector: 'yimify-app-sidebar',
  templateUrl: './yimify-app-sidebar.component.html',
  styleUrls: ['./yimify-app-sidebar.component.scss']
})

export class YimifyAppSideBarComponent implements OnDestroy {
  public navItems = navItems;
  public sidebarMinimized = newLocal;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  alive: boolean = true;

  constructor(private _lang: LanguageService) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this._lang.values
      .takeWhile(() => this.alive)
      .subscribe((response: any) => {
        this.navItems = new Array();
        setTimeout(() => {
          this.navItems = response.nav_items;
        }, 0);
      });
  }

  ngOnDestroy() {
    this.element.classList.remove('header-fixed', 'sidebar-fixed', 'sidebar-lg-show');
  }
}
