import { Deserializable } from './deserializable.model';

export enum Sender {
  Influencer = 1,
  Advertiser = 2
}

export class Message implements Deserializable {
  id?: number;
  advertiser_id?: number;
  campaign_id: number;
  influencer_user_id?: number;
  message: string;
  created_at: number;
  is_read: number;
  sender: Sender;
  files?: Array<any>;
  type?: string;
  src?: string;
  url?: string;

  get IsUnread() {
    return this.sender === Sender.Advertiser && !this.is_read;
  }

  constructor(message: any) {
    this.id = message['id'];
    this.advertiser_id = message['advertiser_id'];
    this.campaign_id = message['campaign_id'];
    this.influencer_user_id = message['influencer_user_id'];
    this.message = message['message'];
    this.sender = message['sender'];
    this.files = message['files'];
    this.is_read = message['is_read'];
    this.created_at = message['created_at'];
    this.type = message['type'];
    this.src = message['src'];
    this.url = message['url'];
  }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}

export interface IMessageHashMap { [campaign_pk: number]: Message[]; }
