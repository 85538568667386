import { Deserializable } from './deserializable.model';

export class PersonalFormFieldsValues implements Deserializable {
  artificial_nail?: number;
  beard?: number;
  body_height?: string;
  children?: number;
  clothing_size_bottom?: string;
  clothing_size_top?: string;
  clothing_style?: string;
  contact_lens?: number;
  eye_color?: string;
  eyelash_extension?: number;
  glasses?: number;
  hair_color?: string;
  hair_extensions?: number;
  hair_length?: string;
  hair_structure?: string;
  languages?: string;
  marital_status?: string;
  nutrition?: string;
  pets?: string;
  piercings?: number;
  shoe_size?: string;
  skin_type?: string;
  sports?: string;
  tattoos?: number;
  influencer_user_id?: number;

  constructor() { }

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
