import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmPasswordValidation} from '../../../../validators/confirm-password.validator';
import {UserService, CookiesService, ErrorService, LanguageService} from '../../../../services';
import {environment} from './../../../../../environments/environment';
import {ToasterService} from 'angular2-toaster';
import {DatePipe} from '@angular/common';
import {DateOfBirthValidator} from '../../../../validators/DateOfBirth.validator';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {deLocale} from 'ngx-bootstrap/locale';
import {BsLocaleService} from 'ngx-bootstrap';

defineLocale('de', deLocale);

@Component({
  templateUrl: 'register.component.html',
  styleUrls: [
    '../login/general.login.component.scss',
    './register.component.scss'
  ]
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  user: any = {};
  errors: string;
  api_error = {};
  success_msg = false;
  terms: string = environment.TERMS;
  privacy: string = environment.PRIVACY;
  registrationStarted: boolean = false;

  constructor(
    fb: FormBuilder, public _router: Router, public _user_service: UserService,
    private _cookiesService: CookiesService, private errorService: ErrorService,
    private localservice: BsLocaleService,
    private _toaster: ToasterService, private _language: LanguageService,
    private datepipe: DatePipe
  ) {
    this.registerForm = fb.group({
        'email': ['', Validators.compose([Validators.required, Validators.email])],
        'username': ['', Validators.required],
        'language': ['', Validators.required],
        'firstname': [''],
        'lastname': [''],
        'password': ['', [Validators.required, Validators.minLength(6)]],
        'date_of_birth': ['', Validators.required],
        'passwordRepeat': ['', [Validators.required, Validators.minLength(6)]],
        'terms': ['', Validators.required],
        'subscription': ['']
      },
      {validator: [ConfirmPasswordValidation.MatchPassword, DateOfBirthValidator.CheckDateOfBirth]});
    this.registerForm.controls['language'].setValue('de-DE');
  }

  ngOnInit() {
    const language = environment.Default_language ? environment.Default_language : 'en';
    this.localservice.use(language);
    let signup_cookie = this._cookiesService.getCookie('signup_data');
    if (signup_cookie) {
      signup_cookie = JSON.parse(signup_cookie);
      Object.keys(signup_cookie).forEach((value, key) => {
        this.registerForm.controls[value].setValue(signup_cookie[value]);
      });
    }
  }

  saveValueToCookie(value, key) {
    let signup_data = {};
    if (this._cookiesService.getCookie('signup_data')) {
      signup_data = JSON.parse(this._cookiesService.getCookie('signup_data'));
    }
    signup_data[key] = value;
    this._cookiesService.setCookie('signup_data', JSON.stringify(signup_data), 0.5);
  }

  register = (signupData: any) => {
    this.api_error = {};
    const funnelDataArray: any[] = [];
    const funnelData = JSON.parse(this._cookiesService.getCookie('question_cookie'));
    funnelData['instagram_code'] = localStorage.getItem('instagram-code') ? localStorage.getItem('instagram-code') : '';
    Object.keys(funnelData).forEach(
      (val, key) => {
        funnelDataArray.push({
          'field': val,
          'value': funnelData[val]
        });
      }
    );
    signupData['date_of_birth'] = this.datepipe.transform(signupData['date_of_birth'], 'yyyy-MM-dd');
    signupData.data = funnelDataArray;
    this.registrationStarted = true;
    this._user_service.signup(signupData).subscribe(res => {
        this.registrationStarted = false;
        if (res.id) {
          this.success_msg = true;
          localStorage.clear();
          this._cookiesService.deleteCookie('signup_data');
          this._cookiesService.deleteCookie('question_cookie');
          // removing the social media codes after user is being registered
          localStorage.removeItem('instagram-code');
          localStorage.removeItem('insta-url');
          this._router.navigate(['/email-confirmation']);
        }
      },
      error => {
        this.registrationStarted = false;
        if (error['error']) {
          const messages = error.error.messages ? error.error.messages : [error.error.message];
          this.api_error = messages;
          this.errorService.error(messages, ['user', 'register']);
        }
      });
  }

  getTranslatedString(string) {
    let translated_string = '';
    this._language.getTranslationUsingKeyString(string)
      .subscribe(
        tranlatedData => {
          translated_string = tranlatedData;
        }
      );
    return translated_string;
  }
}
