import { Pipe, PipeTransform } from '@angular/core';

export enum FileType {
  Image = 'img',
  PDF = 'pdf',
  Unknown = 'Unknown',
}

export function GetFileTypeFromEnding(ending: string): FileType {
  switch (ending.toLowerCase()) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
      return FileType.Image;
    case 'pdf':
      return FileType.PDF;
    default:
      return FileType.Unknown;
  }
}

export function GetFileType(file: string) {
  return GetFileTypeFromEnding(file.match(/\.[0-9a-z]+$/i)[0].replace('.', ''));
}

@Pipe({
  name: 'fileType'
})
export class FileTypePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return GetFileType(value);
  }

}
