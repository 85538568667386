import { Deserializable } from './deserializable.model';

export class PersonalFormFields implements Deserializable {
  artificial_nail?: Array<any>;
  beard?: Array<any>;
  body_height?: Array<any>;
  children?: Array<any>;
  clothing_size_bottom?: Array<any>;
  clothing_size_top?: Array<any>;
  clothing_style?: Array<any>;
  contact_lens?: Array<any>;
  eye_color?: Array<any>;
  eyelash_extension?: Array<any>;
  glasses?: Array<any>;
  hair_color?: Array<any>;
  hair_extensions?: Array<any>;
  hair_length?: Array<any>;
  hair_structure?: Array<any>;
  languages?: Array<any>;
  marital_status?: Array<any>;
  nutrition?: Array<any>;
  pets?: Array<any>;
  piercings?: Array<any>;
  shoe_size?: Array<any>;
  skin_type?: Array<any>;
  sports?: Array<any>;
  tattoos?: Array<any>;

  deserialize(input: any) {
    Object.assign(this, input);
    return this;
  }
}
