import {Component, Input, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {VgAPI} from 'videogular2/compiled/core';

@Component({
  selector: 'video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})

export class VideoPopupComponent {
  // public videoModel;
  @ViewChild(ModalDirective, {static: true}) public videoModel: ModalDirective;
  @Input() videoUrl: string;
  @Input() fileType: string;
  @Input() fileName: string;

  showPopUp: boolean = true;
  vgApi: VgAPI;
  defaultapi: VgAPI;

  constructor() {
  }

  onPlayerReady(api: VgAPI) {
    if (!this.vgApi) {
      return;
    }
    this.vgApi = api;
    this.defaultapi = api;
    const media = this.vgApi.getDefaultMedia();
    if (!media || !media.subscriptions) {
      return;
    }
    media.subscriptions.canPlay.subscribe((response) => {
      this.vgApi.play();
    }, (error) => {
      console.log(error);
    });
  }

  close() {
    // this.vgApi.pause();
    if (this.vgApi) {
      const media = this.vgApi.getDefaultMedia();
      if (!media || !media.subscriptions) {
        return;
      }
      media.subscriptions.bufferDetected.subscribe((response) => {
        if (response) {
          this.showPopUp = false;
          setTimeout(() => {
            this.showPopUp = true;
            this.vgApi = this.defaultapi;
          }, 10);
        }
      });
    }

    // this.api.playerReadyEvent.closed = true;
    // this.api.getDefaultMedia().currentTime = 0;
  }


}
