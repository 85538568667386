import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import 'rxjs/add/operator/toPromise';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {environment} from '../../environments/environment';
import {navItems} from '../_nav';
import {CampaignService} from './campaign.service';
import {Common} from '../models/index';
import {DashboardService} from './dashboard.service';


@Injectable()
export class LanguageService implements OnInit {
  public static default_static_values = JSON.parse(JSON.stringify({
    'default_language': environment.Default_language,
    'nav_items': navItems
  }));

  public default_values = {
    'default_language': environment.Default_language,
    'nav_items': navItems
  };


  public locales = {
    'ab': {
      'name': 'Abkhaz',
      'nativeName': 'аҧсуа'
    },
    'aa': {
      'name': 'Afar',
      'nativeName': 'Afaraf'
    },
    'af': {
      'name': 'Afrikaans',
      'nativeName': 'Afrikaans'
    },
    'ak': {
      'name': 'Akan',
      'nativeName': 'Akan'
    },
    'sq': {
      'name': 'Albanian',
      'nativeName': 'Shqip'
    },
    'am': {
      'name': 'Amharic',
      'nativeName': 'አማርኛ'
    },
    'ar': {
      'name': 'Arabic',
      'nativeName': 'العربية'
    },
    'an': {
      'name': 'Aragonese',
      'nativeName': 'Aragonés'
    },
    'hy': {
      'name': 'Armenian',
      'nativeName': 'Հայերեն'
    },
    'as': {
      'name': 'Assamese',
      'nativeName': 'অসমীয়া'
    },
    'av': {
      'name': 'Avaric',
      'nativeName': 'авар мацӀ, магӀарул мацӀ'
    },
    'ae': {
      'name': 'Avestan',
      'nativeName': 'avesta'
    },
    'ay': {
      'name': 'Aymara',
      'nativeName': 'aymar aru'
    },
    'az': {
      'name': 'Azerbaijani',
      'nativeName': 'azərbaycan dili'
    },
    'bm': {
      'name': 'Bambara',
      'nativeName': 'bamanankan'
    },
    'ba': {
      'name': 'Bashkir',
      'nativeName': 'башҡорт теле'
    },
    'eu': {
      'name': 'Basque',
      'nativeName': 'euskara, euskera'
    },
    'be': {
      'name': 'Belarusian',
      'nativeName': 'Беларуская'
    },
    'bn': {
      'name': 'Bengali',
      'nativeName': 'বাংলা'
    },
    'bh': {
      'name': 'Bihari',
      'nativeName': 'भोजपुरी'
    },
    'bi': {
      'name': 'Bislama',
      'nativeName': 'Bislama'
    },
    'bs': {
      'name': 'Bosnian',
      'nativeName': 'bosanski jezik'
    },
    'br': {
      'name': 'Breton',
      'nativeName': 'brezhoneg'
    },
    'bg': {
      'name': 'Bulgarian',
      'nativeName': 'български език'
    },
    'my': {
      'name': 'Burmese',
      'nativeName': 'ဗမာစာ'
    },
    'ca': {
      'name': 'Catalan; Valencian',
      'nativeName': 'Català'
    },
    'ch': {
      'name': 'Chamorro',
      'nativeName': 'Chamoru'
    },
    'ce': {
      'name': 'Chechen',
      'nativeName': 'нохчийн мотт'
    },
    'ny': {
      'name': 'Chichewa; Chewa; Nyanja',
      'nativeName': 'chiCheŵa, chinyanja'
    },
    'zh': {
      'name': 'Chinese',
      'nativeName': '中文 (Zhōngwén), 汉语, 漢語'
    },
    'cv': {
      'name': 'Chuvash',
      'nativeName': 'чӑваш чӗлхи'
    },
    'kw': {
      'name': 'Cornish',
      'nativeName': 'Kernewek'
    },
    'co': {
      'name': 'Corsican',
      'nativeName': 'corsu, lingua corsa'
    },
    'cr': {
      'name': 'Cree',
      'nativeName': 'ᓀᐦᐃᔭᐍᐏᐣ'
    },
    'hr': {
      'name': 'Croatian',
      'nativeName': 'hrvatski'
    },
    'cs': {
      'name': 'Czech',
      'nativeName': 'česky, čeština'
    },
    'da': {
      'name': 'Danish',
      'nativeName': 'dansk'
    },
    'dv': {
      'name': 'Divehi; Dhivehi; Maldivian;',
      'nativeName': 'ދިވެހި'
    },
    'nl': {
      'name': 'Dutch',
      'nativeName': 'Nederlands, Vlaams'
    },
    'en': {
      'name': 'English',
      'nativeName': 'English'
    },
    'eo': {
      'name': 'Esperanto',
      'nativeName': 'Esperanto'
    },
    'et': {
      'name': 'Estonian',
      'nativeName': 'eesti, eesti keel'
    },
    'ee': {
      'name': 'Ewe',
      'nativeName': 'Eʋegbe'
    },
    'fo': {
      'name': 'Faroese',
      'nativeName': 'føroyskt'
    },
    'fj': {
      'name': 'Fijian',
      'nativeName': 'vosa Vakaviti'
    },
    'fi': {
      'name': 'Finnish',
      'nativeName': 'suomi, suomen kieli'
    },
    'fr': {
      'name': 'French',
      'nativeName': 'français, langue française'
    },
    'ff': {
      'name': 'Fula; Fulah; Pulaar; Pular',
      'nativeName': 'Fulfulde, Pulaar, Pular'
    },
    'gl': {
      'name': 'Galician',
      'nativeName': 'Galego'
    },
    'ka': {
      'name': 'Georgian',
      'nativeName': 'ქართული'
    },
    'de': {
      'name': 'German',
      'nativeName': 'Deutsch'
    },
    'el': {
      'name': 'Greek, Modern',
      'nativeName': 'Ελληνικά'
    },
    'gn': {
      'name': 'Guaraní',
      'nativeName': 'Avañeẽ'
    },
    'gu': {
      'name': 'Gujarati',
      'nativeName': 'ગુજરાતી'
    },
    'ht': {
      'name': 'Haitian; Haitian Creole',
      'nativeName': 'Kreyòl ayisyen'
    },
    'ha': {
      'name': 'Hausa',
      'nativeName': 'Hausa, هَوُسَ'
    },
    'he': {
      'name': 'Hebrew (modern)',
      'nativeName': 'עברית'
    },
    'hz': {
      'name': 'Herero',
      'nativeName': 'Otjiherero'
    },
    'hi': {
      'name': 'Hindi',
      'nativeName': 'हिन्दी, हिंदी'
    },
    'ho': {
      'name': 'Hiri Motu',
      'nativeName': 'Hiri Motu'
    },
    'hu': {
      'name': 'Hungarian',
      'nativeName': 'Magyar'
    },
    'ia': {
      'name': 'Interlingua',
      'nativeName': 'Interlingua'
    },
    'id': {
      'name': 'Indonesian',
      'nativeName': 'Bahasa Indonesia'
    },
    'ie': {
      'name': 'Interlingue',
      'nativeName': 'Originally called Occidental; then Interlingue after WWII'
    },
    'ga': {
      'name': 'Irish',
      'nativeName': 'Gaeilge'
    },
    'ig': {
      'name': 'Igbo',
      'nativeName': 'Asụsụ Igbo'
    },
    'ik': {
      'name': 'Inupiaq',
      'nativeName': 'Iñupiaq, Iñupiatun'
    },
    'io': {
      'name': 'Ido',
      'nativeName': 'Ido'
    },
    'is': {
      'name': 'Icelandic',
      'nativeName': 'Íslenska'
    },
    'it': {
      'name': 'Italian',
      'nativeName': 'Italiano'
    },
    'iu': {
      'name': 'Inuktitut',
      'nativeName': 'ᐃᓄᒃᑎᑐᑦ'
    },
    'ja': {
      'name': 'Japanese',
      'nativeName': '日本語 (にほんご／にっぽんご)'
    },
    'jv': {
      'name': 'Javanese',
      'nativeName': 'basa Jawa'
    },
    'kl': {
      'name': 'Kalaallisut, Greenlandic',
      'nativeName': 'kalaallisut, kalaallit oqaasii'
    },
    'kn': {
      'name': 'Kannada',
      'nativeName': 'ಕನ್ನಡ'
    },
    'kr': {
      'name': 'Kanuri',
      'nativeName': 'Kanuri'
    },
    'ks': {
      'name': 'Kashmiri',
      'nativeName': 'कश्मीरी, كشميري‎'
    },
    'kk': {
      'name': 'Kazakh',
      'nativeName': 'Қазақ тілі'
    },
    'km': {
      'name': 'Khmer',
      'nativeName': 'ភាសាខ្មែរ'
    },
    'ki': {
      'name': 'Kikuyu, Gikuyu',
      'nativeName': 'Gĩkũyũ'
    },
    'rw': {
      'name': 'Kinyarwanda',
      'nativeName': 'Ikinyarwanda'
    },
    'ky': {
      'name': 'Kirghiz, Kyrgyz',
      'nativeName': 'кыргыз тили'
    },
    'kv': {
      'name': 'Komi',
      'nativeName': 'коми кыв'
    },
    'kg': {
      'name': 'Kongo',
      'nativeName': 'KiKongo'
    },
    'ko': {
      'name': 'Korean',
      'nativeName': '한국어 (韓國語), 조선말 (朝鮮語)'
    },
    'ku': {
      'name': 'Kurdish',
      'nativeName': 'Kurdî, كوردی‎'
    },
    'kj': {
      'name': 'Kwanyama, Kuanyama',
      'nativeName': 'Kuanyama'
    },
    'la': {
      'name': 'Latin',
      'nativeName': 'latine, lingua latina'
    },
    'lb': {
      'name': 'Luxembourgish, Letzeburgesch',
      'nativeName': 'Lëtzebuergesch'
    },
    'lg': {
      'name': 'Luganda',
      'nativeName': 'Luganda'
    },
    'li': {
      'name': 'Limburgish, Limburgan, Limburger',
      'nativeName': 'Limburgs'
    },
    'ln': {
      'name': 'Lingala',
      'nativeName': 'Lingála'
    },
    'lo': {
      'name': 'Lao',
      'nativeName': 'ພາສາລາວ'
    },
    'lt': {
      'name': 'Lithuanian',
      'nativeName': 'lietuvių kalba'
    },
    'lu': {
      'name': 'Luba-Katanga',
      'nativeName': ''
    },
    'lv': {
      'name': 'Latvian',
      'nativeName': 'latviešu valoda'
    },
    'gv': {
      'name': 'Manx',
      'nativeName': 'Gaelg, Gailck'
    },
    'mk': {
      'name': 'Macedonian',
      'nativeName': 'македонски јазик'
    },
    'mg': {
      'name': 'Malagasy',
      'nativeName': 'Malagasy fiteny'
    },
    'ms': {
      'name': 'Malay',
      'nativeName': 'bahasa Melayu, بهاس ملايو‎'
    },
    'ml': {
      'name': 'Malayalam',
      'nativeName': 'മലയാളം'
    },
    'mt': {
      'name': 'Maltese',
      'nativeName': 'Malti'
    },
    'mi': {
      'name': 'Māori',
      'nativeName': 'te reo Māori'
    },
    'mr': {
      'name': 'Marathi (Marāṭhī)',
      'nativeName': 'मराठी'
    },
    'mh': {
      'name': 'Marshallese',
      'nativeName': 'Kajin M̧ajeļ'
    },
    'mn': {
      'name': 'Mongolian',
      'nativeName': 'монгол'
    },
    'na': {
      'name': 'Nauru',
      'nativeName': 'Ekakairũ Naoero'
    },
    'nv': {
      'name': 'Navajo, Navaho',
      'nativeName': 'Diné bizaad, Dinékʼehǰí'
    },
    'nb': {
      'name': 'Norwegian Bokmål',
      'nativeName': 'Norsk bokmål'
    },
    'nd': {
      'name': 'North Ndebele',
      'nativeName': 'isiNdebele'
    },
    'ne': {
      'name': 'Nepali',
      'nativeName': 'नेपाली'
    },
    'ng': {
      'name': 'Ndonga',
      'nativeName': 'Owambo'
    },
    'nn': {
      'name': 'Norwegian Nynorsk',
      'nativeName': 'Norsk nynorsk'
    },
    'no': {
      'name': 'Norwegian',
      'nativeName': 'Norsk'
    },
    'ii': {
      'name': 'Nuosu',
      'nativeName': 'ꆈꌠ꒿ Nuosuhxop'
    },
    'nr': {
      'name': 'South Ndebele',
      'nativeName': 'isiNdebele'
    },
    'oc': {
      'name': 'Occitan',
      'nativeName': 'Occitan'
    },
    'oj': {
      'name': 'Ojibwe, Ojibwa',
      'nativeName': 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
    },
    'cu': {
      'name': 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
      'nativeName': 'ѩзыкъ словѣньскъ'
    },
    'om': {
      'name': 'Oromo',
      'nativeName': 'Afaan Oromoo'
    },
    'or': {
      'name': 'Oriya',
      'nativeName': 'ଓଡ଼ିଆ'
    },
    'os': {
      'name': 'Ossetian, Ossetic',
      'nativeName': 'ирон æвзаг'
    },
    'pa': {
      'name': 'Panjabi, Punjabi',
      'nativeName': 'ਪੰਜਾਬੀ, پنجابی‎'
    },
    'pi': {
      'name': 'Pāli',
      'nativeName': 'पाऴि'
    },
    'fa': {
      'name': 'Persian',
      'nativeName': 'فارسی'
    },
    'pl': {
      'name': 'Polish',
      'nativeName': 'polski'
    },
    'ps': {
      'name': 'Pashto, Pushto',
      'nativeName': 'پښتو'
    },
    'pt': {
      'name': 'Portuguese',
      'nativeName': 'Português'
    },
    'qu': {
      'name': 'Quechua',
      'nativeName': 'Runa Simi, Kichwa'
    },
    'rm': {
      'name': 'Romansh',
      'nativeName': 'rumantsch grischun'
    },
    'rn': {
      'name': 'Kirundi',
      'nativeName': 'kiRundi'
    },
    'ro': {
      'name': 'Romanian, Moldavian, Moldovan',
      'nativeName': 'română'
    },
    'ru': {
      'name': 'Russian',
      'nativeName': 'русский язык'
    },
    'sa': {
      'name': 'Sanskrit (Saṁskṛta)',
      'nativeName': 'संस्कृतम्'
    },
    'sc': {
      'name': 'Sardinian',
      'nativeName': 'sardu'
    },
    'sd': {
      'name': 'Sindhi',
      'nativeName': 'सिन्धी, سنڌي، سندھی‎'
    },
    'se': {
      'name': 'Northern Sami',
      'nativeName': 'Davvisámegiella'
    },
    'sm': {
      'name': 'Samoan',
      'nativeName': 'gagana faa Samoa'
    },
    'sg': {
      'name': 'Sango',
      'nativeName': 'yângâ tî sängö'
    },
    'sr': {
      'name': 'Serbian',
      'nativeName': 'српски језик'
    },
    'gd': {
      'name': 'Scottish Gaelic; Gaelic',
      'nativeName': 'Gàidhlig'
    },
    'sn': {
      'name': 'Shona',
      'nativeName': 'chiShona'
    },
    'si': {
      'name': 'Sinhala, Sinhalese',
      'nativeName': 'සිංහල'
    },
    'sk': {
      'name': 'Slovak',
      'nativeName': 'slovenčina'
    },
    'sl': {
      'name': 'Slovene',
      'nativeName': 'slovenščina'
    },
    'so': {
      'name': 'Somali',
      'nativeName': 'Soomaaliga, af Soomaali'
    },
    'st': {
      'name': 'Southern Sotho',
      'nativeName': 'Sesotho'
    },
    'es': {
      'name': 'Spanish; Castilian',
      'nativeName': 'español, castellano'
    },
    'su': {
      'name': 'Sundanese',
      'nativeName': 'Basa Sunda'
    },
    'sw': {
      'name': 'Swahili',
      'nativeName': 'Kiswahili'
    },
    'ss': {
      'name': 'Swati',
      'nativeName': 'SiSwati'
    },
    'sv': {
      'name': 'Swedish',
      'nativeName': 'svenska'
    },
    'ta': {
      'name': 'Tamil',
      'nativeName': 'தமிழ்'
    },
    'te': {
      'name': 'Telugu',
      'nativeName': 'తెలుగు'
    },
    'tg': {
      'name': 'Tajik',
      'nativeName': 'тоҷикӣ, toğikī, تاجیکی‎'
    },
    'th': {
      'name': 'Thai',
      'nativeName': 'ไทย'
    },
    'ti': {
      'name': 'Tigrinya',
      'nativeName': 'ትግርኛ'
    },
    'bo': {
      'name': 'Tibetan Standard, Tibetan, Central',
      'nativeName': 'བོད་ཡིག'
    },
    'tk': {
      'name': 'Turkmen',
      'nativeName': 'Türkmen, Түркмен'
    },
    'tl': {
      'name': 'Tagalog',
      'nativeName': 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
    },
    'tn': {
      'name': 'Tswana',
      'nativeName': 'Setswana'
    },
    'to': {
      'name': 'Tonga (Tonga Islands)',
      'nativeName': 'faka Tonga'
    },
    'tr': {
      'name': 'Turkish',
      'nativeName': 'Türkçe'
    },
    'ts': {
      'name': 'Tsonga',
      'nativeName': 'Xitsonga'
    },
    'tt': {
      'name': 'Tatar',
      'nativeName': 'татарча, tatarça, تاتارچا‎'
    },
    'tw': {
      'name': 'Twi',
      'nativeName': 'Twi'
    },
    'ty': {
      'name': 'Tahitian',
      'nativeName': 'Reo Tahiti'
    },
    'ug': {
      'name': 'Uighur, Uyghur',
      'nativeName': 'Uyƣurqə, ئۇيغۇرچە‎'
    },
    'uk': {
      'name': 'Ukrainian',
      'nativeName': 'українська'
    },
    'ur': {
      'name': 'Urdu',
      'nativeName': 'اردو'
    },
    'uz': {
      'name': 'Uzbek',
      'nativeName': 'zbek, Ўзбек, أۇزبېك‎'
    },
    've': {
      'name': 'Venda',
      'nativeName': 'Tshivenḓa'
    },
    'vi': {
      'name': 'Vietnamese',
      'nativeName': 'Tiếng Việt'
    },
    'vo': {
      'name': 'Volapük',
      'nativeName': 'Volapük'
    },
    'wa': {
      'name': 'Walloon',
      'nativeName': 'Walon'
    },
    'cy': {
      'name': 'Welsh',
      'nativeName': 'Cymraeg'
    },
    'wo': {
      'name': 'Wolof',
      'nativeName': 'Wollof'
    },
    'fy': {
      'name': 'Western Frisian',
      'nativeName': 'Frysk'
    },
    'xh': {
      'name': 'Xhosa',
      'nativeName': 'isiXhosa'
    },
    'yi': {
      'name': 'Yiddish',
      'nativeName': 'ייִדיש'
    },
    'yo': {
      'name': 'Yoruba',
      'nativeName': 'Yorùbá'
    },
    'za': {
      'name': 'Zhuang, Chuang',
      'nativeName': 'Saɯ cueŋƅ, Saw cuengh'
    }
  };

  notifications: any;
  campaignTotalCount: number;

  public values: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private _translate: TranslateService, private http: HttpClient, private _campaignService: CampaignService,
              private _dashboardService: DashboardService,
              private _toaster: ToasterService) {
    if (localStorage.getItem('user')) {
      const user_data = JSON.parse(localStorage.getItem('user'));
      const language = user_data['language'] === 'de-DE' ? 'de' : 'en';
      this.setLanguage(language);
    } else {
      this.setLanguage(environment.Default_language);
    }
  }

  ngOnInit() {

  }

  setLanguage(language: string) {
    this._translate.setDefaultLang(language);
    this.default_values['default_language'] = language;
    for (let i = 0; i < LanguageService.default_static_values['nav_items'].length; i++) {
      this.getTranslation(LanguageService.default_static_values['nav_items'][i]['name']).subscribe((response) => {
        this.default_values['nav_items'][i]['name'] = response;
      });
      if (LanguageService.default_static_values['nav_items'][i]['children']
        && LanguageService.default_static_values['nav_items'][i]['children'].length > 0) {
        for (let j = 0; j < LanguageService.default_static_values['nav_items'][i]['children'].length; j++) {
          this.getTranslation(LanguageService.default_static_values['nav_items'][i]['children'][j]['name']).subscribe((response) => {
            this.default_values['nav_items'][i]['children'][j]['name'] = response;
          });
        }
      }
    }
    this.values.next(this.default_values);
  }

  getTranslation(term: string) {
    return this._translate.get(term);
  }

  changeUserLanguageAPI(language: string) {
    const data: any = {
      'language': language === 'de' ? 'de-DE' : 'en-US'
    };
    return this.http.post<any>(environment.API + '/influencer/user/changelanguage', data).toPromise();
  }

  changeUserLanguage(language: string) {
    this.changeUserLanguageAPI(language).then((status: boolean) => {
      if (status) {
        this.default_values = JSON.parse(JSON.stringify(LanguageService.default_static_values));
        const user_data = JSON.parse(localStorage.getItem('user'));
        user_data['language'] = language === 'de' ? 'de-DE' : 'en-US';
        localStorage.setItem('user', JSON.stringify(user_data));
        localStorage.removeItem('myStatus');
        localStorage.removeItem('globalStatus');
        this.setLanguage(language);
        this.addRouteToNavigation();
      }
    });
  }

  getTranslationUsingKeyString(key: string) {
    return this._translate.get(key);
  }

  addRouteToNavigation() {
    let campaignIndexInNavigation: number;
    let campaignString: string;
    this.getTranslationUsingKeyString('SIDEBAR.CAMPAIGN')
      .subscribe((value) => {
        campaignString = value;
        for (const nav of this.default_values['nav_items']) {
          if (nav.name === campaignString) {
            campaignIndexInNavigation = this.default_values['nav_items'].indexOf(nav);
            this.default_values['nav_items'][campaignIndexInNavigation]['children'] = [];
          }
        }
        if (localStorage.getItem('authenticated') !== null && localStorage.getItem('authenticated') === '1') {
          if (localStorage.getItem('myStatus') && localStorage.getItem('myStatus') !== 'undefined') {
            const myStatus = JSON.parse(localStorage.getItem('myStatus'));
            this.setCampaignStatusNavigation(myStatus, campaignIndexInNavigation);
          } else {
            this._campaignService.getMyCampaignStatus()
              .subscribe(
                (response: Common[]) => {
                  const myStatus: Common[] = response;
                  localStorage.setItem('myStatus', JSON.stringify(myStatus));
                  this.setCampaignStatusNavigation(myStatus, campaignIndexInNavigation);
                },
                error => {
                  this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
                }
              );
          }
          if (localStorage.getItem('globalStatus') === null || localStorage.getItem('globalStatus') === 'undefined') {
            this._campaignService.getCampaignStatus()
              .subscribe(
                (response: Common[]) => {
                  localStorage.setItem('globalStatus', JSON.stringify(response));
                },
                error => {
                  this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
                  // console.log(error);
                }
              );
          }
        }
      });
  }

  setCampaignStatusNavigation(myStatus, campaignIndexInNavigation) {
    this.default_values['nav_items'][campaignIndexInNavigation]['children'] = [];
    for (const navItem of myStatus) {
      this.default_values['nav_items'][campaignIndexInNavigation]['children'].push({
        name: navItem.name,
        url: '/campaign/' + navItem.name.toLowerCase(),
        icon: navItem.icon,
        // badge: {variant: 'primary badge-pill', text: 2}
      });
    }
    this.getNofifications();
  }

  getNofifications() {
    this._dashboardService.getNotification()
      .subscribe(
        (res) => {
          this.notifications = res;
          this.setNotificationsToNavItems();
        },
        error => {
          this._toaster.pop('error', this.getTranslatedString('MESSAGES.MSG_500'));
        });
  }

  setNotificationsToNavItems() {
    this.campaignTotalCount = 0;
    for (const notification of this.notifications) {
      switch (notification['type']) {
        case 'campaign-apply':
          this.default_values['nav_items'][0]['badge'] = {
            variant: 'primary badge-pill', text: notification['count']
          };
          break;
        case 'message':
          this.default_values['nav_items'][2]['badge'] = {
            variant: 'primary badge-pill', text: notification['count']
          };
          break;
        case 'campaign-invite':
          this.default_values['nav_items'][1]['children'][0]['badge'] = {
            variant: 'primary badge-pill', text: notification['count']
          };
          this.campaignTotalCount += notification['count'];
          break;
        case 'campaign-accepted':
          this.default_values['nav_items'][1]['children'][1]['badge'] = {
            variant: 'primary badge-pill', text: notification['count']
          };
          this.campaignTotalCount += notification['count'];
          break;
      }
    }
    if (this.campaignTotalCount > 0) {
      this.default_values['nav_items'][1]['badge'] = {
        variant: 'primary badge-pill', text: this.campaignTotalCount
      };
    }
  }

  removeNotification(type: string) {
    switch (type) {
      case 'campaign-apply':
        delete this.default_values['nav_items'][0]['badge'];
        break;
      case 'message':
        delete this.default_values['nav_items'][2]['badge'];
        break;
      case 'campaign-invite':
        delete this.default_values['nav_items'][1]['children'][0]['badge'];
        delete this.default_values['nav_items'][1]['badge'];
        break;
      case 'campaign-accepted':
        delete this.default_values['nav_items'][1]['children'][1]['badge'];
        delete this.default_values['nav_items'][1]['badge'];
        break;
    }
    this.getNofifications();
  }

  getTranslatedString(string) {
    let translated_string = '';
    this.getTranslationUsingKeyString(string)
      .subscribe(
        tranlatedData => {
          translated_string = tranlatedData;
        }
      );
    return translated_string;
  }

  getLanguageNameByCode(code) {
    if (this.locales[code]) {
      return this.locales[code]['name'];
    }
    return code;
  }
}
