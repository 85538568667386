import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  private tooltips;
  private tooltipChangeSubject = new Subject();

  constructor(private _http: HttpClient) {
  }

  getTooltips() {
    return this._http.get(`${environment.API}/influencer/tooltip/show`);
  }

  getTooltipChangeSubscription() {
    return this.tooltipChangeSubject;
  }

  fetchTooltips() {
    this.getTooltips().subscribe((response) => {
      this.tooltips = response;
      this.tooltipChangeSubject.next();
    });
  }

  async getTooltip(key) {
    if (!this.tooltips) {
      this.tooltips = await this.getTooltips().toPromise();
    }
    if (key && this.tooltips) {
      return this.tooltips[key];
    }
    return null;
  }
}
