import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService, LanguageService, TooltipService} from '../../services';

@Component({
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})

export class DefaultLayoutComponent implements OnInit, OnDestroy {
  showLoader: boolean;
  status: boolean = true;


  constructor(private _language: LanguageService,
              private _tooltipService: TooltipService,
              private _languageService: LanguageService) {
  }

  ngOnInit() {
    LoaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this._languageService.values.subscribe(() => {
      this._tooltipService.fetchTooltips();
    });
  }

  ngOnDestroy() {
    this.status = false;
  }

  getNotifications() {
    this._language.getNofifications();
  }
}
